@font-face {
	font-family: 'NeueMachina-Light';
	src: url('../../assets/font/NeueMachina-Light.otf');
	font-weight: 300;
}

@font-face {
	font-family: 'NeueMachina-Regular';
	src: url('../../assets/font/NeueMachina-Regular.otf');
	font-weight: 400;
}

@font-face {
	font-family: 'JetBrains-Regular';
	src: url('../../assets/font/JetBrainsMono-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'SF-Bold';
	src: url('../../assets/font/JetBrainsMono-Bold.ttf');
	font-weight: 1000;
}


@font-face {
	font-family: 'JetBrains-Light';
	src: url('../../assets/font/JetBrainsMono-Light.ttf');
	font-weight: 200;
}

@font-face {
	font-family: 'JetBrains-Medium';
	src: url('../../assets/font/JetBrainsMono-Medium.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Milligram-Bold';
	src: url('../../assets/font/Milligram-Bold-trial.ttf');
	font-weight: 1000;
}

@font-face {
	font-family: 'Milligram-Regular';
	src: url('../../assets/font/Milligram-Regular-trial.ttf');
	font-weight: 400;
}



// @font-face {
// 	font-family: 'NeueMachina-Bold';
// 	src: url('../../assets/font/NeueMachina-Ultrabold.otf');
// 	font-weight: 400;
// }

@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Light'), local('../../assets/font/Rubik-Light'), url('../../assets/font/Rubiklight.woff2') format('woff2'), url('../../assets/font/Rubiklight.woff') format('woff'), url('../../assets/font/Rubiklight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Light Italic'), local('../../assets/font/Rubik-LightItalic'), url('../../assets/font/Rubiklightitalic.woff2') format('woff2'), url('../../assets/font/Rubiklightitalic.woff') format('woff'), url('../../assets/font/Rubiklightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik'), local('../../assets/font/Rubik-Regular'), url('../../assets/font/Rubik.woff2') format('woff2'), url('../../assets/font/Rubik.woff') format('woff'), url('../../assets/font/Rubik.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Italic'), local('../../assets/font/Rubik-Italic'), url('../../assets/font/Rubikitalic.woff2') format('woff2'), url('../../assets/font/Rubikitalic.woff') format('woff'), url('../../assets/font/Rubikitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Medium'), local('../../assets/font/Rubik-Medium'), url('../../assets/font/Rubikmedium.woff2') format('woff2'), url('../../assets/font/Rubikmedium.woff') format('woff'), url('../../assets/font/Rubikmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Medium Italic'), local('../../assets/font/Rubik-MediumItalic'), url('../../assets/font/Rubikmediumitalic.woff2') format('woff2'), url('../../assets/font/Rubikmediumitalic.woff') format('woff'), url('../../assets/font/Rubikmediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Bold'), local('../../assets/font/Rubik-Bold'), url('../../assets/font/Rubikbold.woff2') format('woff2'), url('../../assets/font/Rubikbold.woff') format('woff'), url('../../assets/font/Rubikbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Bold Italic'), local('../../assets/font/Rubik-BoldItalic'), url('../../assets/font/Rubikbolditalic.woff2') format('woff2'), url('../../assets/font/Rubikbolditalic.woff') format('woff'), url('../../assets/font/Rubikbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Black'), local('../../assets/font/Rubik-Black'), url('../../assets/font/Rubikblack.woff2') format('woff2'), url('../../assets/font/Rubikblack.woff') format('woff'), url('../../assets/font/Rubikblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik';
    src: local('../../assets/font/Rubik Black Italic'), local('../../assets/font/Rubik-BlackItalic'), url('../../assets/font/Rubikblackitalic.woff2') format('woff2'), url('../../assets/font/Rubikblackitalic.woff') format('woff'), url('../../assets/font/Rubikblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF-Medium';
    src: url('../../assets/font/sf/SFProDisplay-Medium.eot');
    src: local('SF Pro Display Medium'), local('../../assets/font/sf/SFProDisplay-Medium'),
        url('../../assets/font/sf/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Medium.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Thin';
    src: url('../../assets/font/sf/SFProDisplay-Thin.eot');
    src: local('SF Pro Display Thin'), local('../../assets/font/sf/SFProDisplay-Thin'),
        url('../../assets/font/sf/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Thin.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Light';
    src: url('../../assets/font/sf/SFProDisplay-Light.eot');
    src: local('SF Pro Display Light'), local('../../assets/font/sf/SFProDisplay-Light'),
        url('../../assets/font/sf/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Light.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Bold';
    src: url('../../assets/font/sf/SFProDisplay-Bold.eot');
    src: local('SF Pro Display Bold'), local('../../assets/font/sf/SFProDisplay-Bold'),
        url('../../assets/font/sf/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Bold.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Ultralight';
    src: url('../../assets/font/sf/SFProDisplay-Ultralight.eot');
    src: local('SF Pro Display Ultralight'), local('../../assets/font/sf/SFProDisplay-Ultralight'),
        url('../../assets/font/sf/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Regular';
    src: url('../../assets/font/sf/SFProDisplay-Regular.eot');
    src: local('SF Pro Display Regular'), local('../../assets/font/sf/SFProDisplay-Regular'),
        url('../../assets/font/sf/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/font/sf/SFProDisplay-Regular.woff2') format('woff2'),
        url('../../assets/font/sf/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

