.login-page-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: fit-content;
    margin-top: 140px;

    .login-card-header {
        font-size: 28px;
        color: #fff;
        font-family: 'SF-Bold';
        margin: 0;
        line-height: 42px;
    }
    
    .login-card-subheader {
        font-size: 17px;
        line-height: 28px;
        margin: 0;
        color: #b2b2b2;
        font-family: 'SF-Regular';
    }

    .login-card {
        width: 100%;
        margin-left: 24vw;
        margin-right: 24vw;
    }

    .login-form {
        margin-top: 50px;
    }

    .login-card-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
}

.login-enter-code-modal {
    .login-modal-enter-code-error-text {
        font-size: 18px;
        color: rgb(139, 54, 54);
        font-family: 'SF-Regular';
        margin: 0;
        line-height: 26px;
        margin-top: 10px;
    }

    .login-enter-code-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
        transition: .2s;

        &.load {
            opacity: .5;
        }

        .login-enter-code-item-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 35px 30px;
            border: 1px solid hsla(30,0%,100%,.1);
            margin: 0 6px;
            border-radius: 8px;
            
            .login-enter-code-item {
                font-size: 28px;
                color: #fff;
                font-family: 'SF-Bold';
                margin: 0;
                line-height: 42px;
                opacity: 0;
                transition: .1s;

                &.show {
                    opacity: 1;
                }
            }
        }
    }

    .login-enter-code-hidden-input {
        position: absolute;
        opacity: 0;
    }

    .login-modal-enter-code-header {
        font-size: 28px;
        color: #fff;
        font-family: 'SF-Bold';
        margin: 0;
        line-height: 42px;
    }

    .login-modal-enter-code-subheader {
        font-size: 17px;
        line-height: 28px;
        margin: 0;
        color: #b2b2b2;
        font-family: 'SF-Regular';
    }
}