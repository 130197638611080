.about-page-content-wrapper {
    max-width: 1170px;
    padding-bottom: 120px;
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    z-index: 1;
    margin: 0 auto;

    .about-page-description-subheader {
        margin-top: 80px;
        text-transform: uppercase;
        margin-bottom: 40px;
        font-family: 'SF-Bold', sans-serif;
        color: #2a2927;
        font-size: 30px;
        line-height: 1.2em;
        font-weight: 500;
        text-transform: uppercase;

        @media screen and (max-width: 680px) {
            font-size: 27px;
        }

        @media screen and (max-width: 580px) {
            font-size: 23px;
        }

        @media screen and (max-width: 380px) {
            font-size: 20px;
        }
    }

    .about-page-content-header {
        position: relative;
        font-size: 101px;
        font-family: 'SF-Bold';
        margin-top: 60px;
        text-transform: uppercase;
        margin-left: -0.65em;
        margin-bottom: 120px;

        @media screen and (max-width: 1300px) {
            margin-left: 0;
        }

        @media screen and (max-width: 680px) {
            font-size: 78px;
        }

        @media screen and (max-width: 580px) {
            font-size: 58px;
        }

        @media screen and (max-width: 380px) {
            font-size: 38px;
        }
    }

    .about-page-content-container {


        .about-page-description-container {
            max-width: 740px;
            width: 100%;
            margin: 0 auto;

            .about-page-description-header {
                margin-top: 100px;
                margin-bottom: 40px;
                font-family: 'SF-Bold', sans-serif;
                color: #2a2927;
                font-size: 56px;
                line-height: 1.2em;
                font-weight: 500;
                text-transform: uppercase;

                @media screen and (max-width: 580px) {
                    font-size: 38px;
                }

                @media screen and (max-width: 380px) {
                    font-size: 28px;
                }

                .primary {
                    color: #5c03bc;
                }
            }

            .about-page-description {
                margin-bottom: 2em;
                color: #828282;
                font-family: 'SF-Regular', sans-serif;
            }

            .about-page-description-image {
                width: 100%;
                margin-top: 60px;
                margin-bottom: 60px;
            }

            .about-page-description-quote-container {
                margin-top: 60px;
                margin-bottom: 60px;
                padding: 50px;
                border-left-style: none;
                border-radius: 30px;
                background-color: #2a2927;
                font-family: 'SF-Bold', sans-serif;
                color: #fff;
                font-size: 24px;
                line-height: 2em;
                font-weight: 500;
                text-transform: uppercase;

                .about-page-description-quote {
                    margin: 0;

                    &::before,
                    &::after {
                        content: '</>';
                        margin: 0 10px;
                        color: #5c03bc;
                    }
                }
            }
        }
    }

    .about-page-roadmap-container {
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        max-width: 740px;
        width: 100%;
        margin: 0 auto;

        .about-page-roadmap-item-container {
            background-color: #fff;
            padding: 20px;
            flex: 1;
            min-width: 300px;
            border-radius: 20px;
            box-shadow: 1px 1px 29px -12px rgba(0, 0, 0, 0.75);

            .about-page-roadmap-header {
                margin: 0;
                margin-top: 20px;
                font-family: 'SF-Bold', sans-serif;
                font-size: 20px;
            }

            .about-page-roadmap-description-container {
                margin-top: 20px;

                .about-page-roadmap-description-row {
                    margin: 0;
                    font-family: 'SF-Regular', sans-serif;
                    color: #828282;
                    font-size: 15px;
                }
            }

            .about-page-roadmap-state-container {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;

                &.completed {
                    .about-page-roadmap-state-indicator {
                        background-color: rgba(6, 240, 3, 0.75);
                        box-shadow: 1px 1px 29px 2px rgba(6, 240, 3, 0.75);
                    }
                }

                &.process {
                    .about-page-roadmap-state-indicator {
                        background-color: rgba(240, 3, 3, 0.75);
                        box-shadow: 1px 1px 29px 2px rgba(240, 3, 3, 0.75);
                    }
                }

                .about-page-roadmap-state-indicator {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;

                }

                .about-page-roadmap-state-text {
                    color: #828282;
                    font-family: 'SF-Regular', sans-serif;
                    margin: 0;
                    font-size: 15px;
                }
            }
        }
    }
}