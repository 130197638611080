.back-three-lines-container {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;

    .back-three-lines-item-container {
        width: 2px;
        height: 100%;
        background-color: rgba(0,0,0,.06);
        position: relative;
        
        .back-three-lines-element {
            background-color: #5c03bc;
            width: 100%;
            position: absolute;
            height: 24px;
            animation: 4s ease-in tarifsBackAnim infinite;

            &.second {
                animation-delay: -.5s;
                animation-duration: 2s;
            }
        }   

        @keyframes tarifsBackAnim {
            0% {
                bottom: 0;
                opacity: 0;
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 0;
                bottom: 100%;
              }
        }

        &.first {
        }

        &.second {

            .back-three-lines-element {
                animation-delay: -.3s;
            }
        }

        &.third {

            .back-three-lines-element {
                animation-delay: -.1s;
            }
        }
    }
}