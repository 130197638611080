.footer-wrapper {
    background-color: #15111b;
    padding: 60px 0px;
    z-index: 2;

    &.light {
        background-color: #fff;

        .footer-language-selector-wrapper {
            @media screen and (max-width: 900px) {
                margin-top: 30px;
                display: flex;
                justify-content: center;
            }
        
            .footer-language-selector-container {
                position: relative;
                margin: 0;
                padding: 10px 40px;
                width: fit-content;
                font-size: 15px;
                border-radius: 50px;
                border: 1px solid #2A2927;
                color: #2A2927;
                font-family: 'SF-Regular';
                transition: .2s ease-in-out;
                cursor: pointer;
        
                .footer-language-selector-select {
                    opacity: 0;
                    position: absolute;
                    cursor: pointer;
                    left: 0;
                    width: 100%;
                    top: 0;
                    height: 100%;
                }
        
                .footer-language-selector-title {
                    color: #2A2927;
                }
            }
        }
    }
}

.footer-container {
    position: relative;
    z-index: 2;
    backdrop-filter: blur(20px);
    padding: 80px;
    border: 1px solid hsla(30,0%,100%,.1);
    border-radius: 30px;

    @media screen and (max-width: 860px) {
        padding: 30px;
    }

    &.light {
        .footer-content-container {
            .footer-right-container {
                .footer-right-resources-container {
                    .footer-right-resources-header {
                        color: #2A2927;
                    }
                }
            }
        }

        border: 1px solid rgba(0, 0, 0, 0.06);
    }

    .footer-content-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 20px;
        row-gap: 30px;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .footer-left-container {
            display: flex;
            flex: 1;
            cursor: pointer;
            flex-direction: column;

            @media screen and (max-width: 900px) {
                justify-content: center;
            }

            .footer-left-logo-container {
                flex: 1;
                display: flex;
                align-items: center;
            }

            // @media screen and (max-width: 860px) {
            //     display: none;
            // }
        }

        .footer-right-container {
            display: flex;
            flex: 1;
            justify-content: end;
            gap: 30px;

            @media screen and (max-width: 860px) {
                justify-content: space-between;
            }

            .footer-right-resources-container {
                display: flex;
                flex-direction: column;
                flex: 1;

                &.download {
                    @media screen and (max-width: 700px) {
                        display: none;
                    }
                }

                .footer-right-resources-header {
                    font-size: 16px;
                    color: #fff;
                    font-family: 'SF-Bold';
                    margin: 0;
                    padding-bottom: 20px;
                }

                .footer-right-resource-item {
                    text-decoration: none;
                    font-size: 14px;
                    font-family: 'SF-Regular';
                    margin-top: 14px;
                    color: #828282;
                    transition: .2s;

                    &:hover {
                        color: #5c03bc;
                    }
                }
            }
        }
    }

    .footer-bottom-container {
        border-top: 1px solid hsla(30,0%,100%,.1);
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .footer-bottom-item {
            font-size: 12px;
            margin: 0;
            font-family: 'SF-Regular';
            color: #828282;
        }
    }
}

.footer-language-selector-wrapper {
    @media screen and (max-width: 900px) {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    .footer-language-selector-container {
        position: relative;
        margin: 0;
        padding: 10px 40px;
        width: fit-content;
        font-size: 15px;
        border-radius: 50px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: #d9d9d9;
        font-family: 'SF-Regular';
        transition: .2s ease-in-out;
        cursor: pointer;

        .footer-language-selector-select {
            opacity: 0;
            position: absolute;
            cursor: pointer;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
        }

        .footer-language-selector-title {
            color: #fff;
        }
    }
}