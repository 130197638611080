// .update-modal-success-result {
//     text-align: center;
//     width: 100%;
//     font-size: 19px;
//     color: #fff;
//     font-weight: bold;
//     margin: 10px 0;
// }

// .update-page-container {
//     margin-top: 200px;
//     z-index: 1;
    
//     .update-page-header {
//         z-index: 1;
//         text-align: center;
//         width: 100%;
//         font-size: 36px;
//         color: #fff;
//         font-weight: bold;
//         margin: 0;
//     }

//     .update-page-content-container {
//         z-index: 1;
//         background-color: rgba($color: #000000, $alpha: .3);
//         margin: 0 20px;
//         display: flex;
//         margin-top: 50px;
//         flex-direction: row;
//         flex-wrap: wrap;
//         align-items: flex-start;
//         padding: 20px !important;
//         border-radius: 20px;

//         .update-page-updates-list-container {
//             z-index: 1;
//             flex-direction: column;
//             display: flex;
//             max-height: 500px;
//             overflow-y: auto;
//             flex: 1;
//             flex-grow: 3;
//             min-width: 260px;
//             padding: 20px !important;
//         }

//         .update-page-update-item-container {
//             .update-page-update-item-footer-container {
//                 margin-top: 25px !important;

//                 .update-page-update-item-footer-notice {
//                     font-size: 15px;
//                     color: #fff;
//                 }

//                 .update-page-update-item-footer-text {
//                     font-size: 14px;
//                     color: #fff;
//                     font-weight: bold;
//                 }
//             }

//             .update-page-update-item-body-container {
//                 margin-top: 30px;

//                 .update-page-update-item-body-header {
//                     font-size: 18px;
//                     color: #fff;
//                     font-weight: bold;
//                     margin: 0;
//                 }

//                 .update-page-update-item-body-features-list-container {
//                     margin-top: 25px;

//                     .update-page-update-item-body-feature {
//                         margin-top: 15px;
//                         font-size: 15px;
//                         color: #fff;
//                     }
//                 }
//             }

//             .update-page-update-item-top-container {
//                 padding-bottom: 8px;
//                 border-bottom: 1px solid rgba($color: #fff, $alpha: .3);

//                 .update-page-update-item-header {
//                     font-size: 17px;
//                     color: #fff;
//                     font-weight: bold;
//                     margin: 0;
//                 }

//                 .update-page-update-subheader {
//                     font-size: 14px;
//                     color: #fff;
//                     opacity: .5;
//                     margin: 0;
//                 }
//             }
//         }

//         .update-page-form-container {
//             z-index: 1;
//             padding: 20px !important;
//             display: flex;
//             height: 500px;
//             min-width: 260px;
//             flex-grow: 1;
//             flex-basis: 260px;
//             flex-direction: column;

//             .update-page-form-error {
//                 font-size: 14px;
//                 color:rgb(163, 0, 0);
//                 margin: 10px 0px;;
//             }

//             .update-page-form-header {
//                 width: 100%;
//                 font-size: 17px;
//                 color: #fff;
//                 font-weight: bold;
//                 margin: 0;
//             }

//             .update-page-form-subheader {
//                 width: 100%;
//                 font-size: 14px;
//                 color: #fff;
//                 opacity: .5;                
//                 margin: 0;
//             }

//             .update-page-form-button-container {
//                 margin-top: 30px;
//                 display: flex;
//                 align-items: flex-end;
//                 flex-direction: row;
//                 justify-content: flex-end;
//                 height: fill-available;
//             }
//         }
//     }
// }

// .background-slipes {
//     z-index: -1;
// }


// .update-old-version {
//     opacity: .5;
// }

// .update-page-content {
//     margin-top: 160px;
//     padding: 10px;

//     .update-page-header {
//         font-size: 18px;
//         width: 100%;
//         color: #fff;
//         text-align: center;
//         font-weight: bold;
//         margin: 0;
//     }

//     .update-page-subheader {
//         font-size: 15px;
//         width: 100%;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//         margin-top: 30px;
//     }

//     .update-page-info-header {
//         font-size: 16px;
//         width: 100%;
//         font-weight: bold;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//         margin-top: 50px;
//         padding-bottom: 20px;
//     }

//     .update-page-info-item {
//         font-size: 14px;
//         width: 100%;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//         margin-top: 10px;
//     }

//     .update-bottom-header {
//         font-size: 16px;
//         width: 100%;
//         font-weight: bold;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//         margin-top: 50px;
//         padding-bottom: 20px;
//     }

//     .update-bottom-desc {
//         font-size: 14px;
//         width: 100%;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//     }

//     .update-bottom-final {
//         font-size: 14px;
//         width: 100%;
//         color: #fff;
//         text-align: center;
//         margin: 0;
//         margin-top: 40px;
//     }
// }

.update-container {
    margin: 60px 14vw;
    
    // @media screen and (max-width: 860px) {
    //     margin: 60px 14vw;
    // }
}

@media (max-width: 900px) {
    .update-page-horizontal-content-container {
        visibility: visible !important;
    }

    .update-page-navigation-horizontal-menu-container {
        visibility: visible !important;
    }

    .update-page-horizontal-navigation-container {
        visibility: visible !important;
    }

    .update-page-navigation-container {
        display: none !important;
        visibility: hidden !important;
    }

    .update-page-content-container {
        visibility: hidden !important;
        display: none !important;
    }

    .update-page-container {
        flex-direction: column !important;
    }
}

@media (min-width: 900px) {
    .update-page-horizontal-navigation-container {
        visibility: hidden !important;
        display: none !important;
    }

    .update-page-horizontal-content-container {
        display: none !important;
        visibility: hidden !important;
    }

    .update-page-navigation-horizontal-menu-container {
        display: none !important;
        visibility: hidden !important;
    }

    .update-page-navigation-container {
        display: block !important;
        visibility: visible !important;
    }

    .update-page-content-container {
        visibility: visible !important;
    }

    .update-page-container {
        visibility: hidden !important;
        flex-direction: row !important;
    }
}

.update-page-container {
    display: flex;
    align-items: center;

    .update-page-horizontal-content-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: .2s;

        &.hide {
            display: none;
        }

        animation: showHorizontalPageContent .4s;

        @keyframes showHorizontalPageContent {
            from {
                opacity: 0;
            } to {
                opacity: 1;
            }
        }

        .update-page-content-header {
            color: #2A2927;
            font-weight: bold;
            width: 100%;
            text-align: center;
            font-family: 'SF-Bold';
            font-size: 24px;
            margin: 0;
            transition: .2s;
        }

        .update-page-content-body {
            color: #2A2927;
            font-size: 15px;
            font-family: 'SF-Regular';
            width: 100%;
            text-align: center;
            margin: 0;
            margin-top: 40px;
            transition: .2s;
        }
    }

    .update-page-content-container {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        &.hide {
            display: none;
        }

        animation: showPageContent .4s;

        @keyframes showPageContent {
            from {
                opacity: 0;
            } to {
                opacity: 1;
            }
        }

        .update-page-content-header {
            color: #2A2927;
            font-weight: bold;
            font-size: 28px;
            font-family: 'SF-Bold';
            margin: 0;
        }

        .update-page-content-body {
            color: #2A2927;
            font-size: 18px;
            font-family: 'SF-Regular';
            margin: 0;
            margin-top: 40px;
        }
    }

    .update-page-navigation-container {
        padding: 0 20px !important;
        width: 300px;

        &::after {
            // height: 90vh;
            // background-color: #2A2927;
            // width: 2px;
            // top: 3vh;
            // left: 300px;
            // position: absolute;
            // content: '';
        }

        .update-page-navigation-menu-container {
            width: 300px;
            height: 256px;
            overflow: hidden;

            .update-page-navigation-menu-list {
                padding-top: 50 !important;
                transition: .2s;

                .version-item-container {
                    display: flex;
                    position: relative;
                    flex-direction: row;
                    align-items: center;
                    height: 50px;
                    width: 300px;
                    justify-content: space-between;
                
                    .version-item-indicator {
                        width: 8px;
                        height: 8px;
                        background-color: #2A2927;
                        transition: .2s;
                        margin-right: 15px;
                
                        &.active {
                            width: 16px;
                            height: 16px;
                            margin-right: 10px;
                        }
                    }
                
                    .version-item-text {
                        font-size: 19px;
                        font-family: 'SF-Bold';
                        color: #2A2927;
                        font-weight: 600;
                        margin: 10px 0;
                        transition: .3s;
                        -webkit-touch-callout: none; /* iOS Safari */
                        -webkit-user-select: none; /* Safari */
                         -khtml-user-select: none; /* Konqueror HTML */
                           -moz-user-select: none; /* Old versions of Firefox */
                            -ms-user-select: none; /* Internet Explorer/Edge */
                                user-select: none;
                
                        &:hover {
                            opacity: .7;
                        }
                
                        &.active {
                            font-weight: 1000;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.update-page-horizontal-navigation-container {
    width: 100%;
    // display: flex;
    justify-content: center;

    // &::before {
    //     height: 2px;
    //     background: linear-gradient(0deg, rgba(255, 255, 255,0) 0%, rgba(255, 255, 255,1) 20%, rgba(255, 255, 255,1) 80%, rgba(255, 255, 255,0) 100%);
    //     width: 90vh;
    //     bottom: 55px;
    //     position: absolute;
    //     content: '';
    // }

    .update-page-navigation-horizontal-menu-container {
        width: 100%;
        overflow: hidden;
        flex-direction: row;
        align-items: center;
    
        .update-page-navigation-horizontal-menu-list {
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: .2s;
            margin-top: 30px;

            .version-horizontal-item-container {
                display: flex;
                position: relative;
                flex-direction: column;
                align-items: center;
                height: 70px;
                width: 140px !important;
                justify-content: center;

                .version-horizontal-item-indicator {
                    width: 8px;
                    height: 8px;
                    background-color: #2A2927;
                    transition: .2s;
                    margin-right: 15px;
            
                    &.active {
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }
                }
            
                .version-horizontal-item-text {
                    font-size: 13px;
                    color: #2A2927;
                    font-family: 'SF-Bold';
                    font-weight: 600;
                    margin: 10px 0;
                    transition: .3s;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                     -khtml-user-select: none; /* Konqueror HTML */
                       -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                            user-select: none;
            
                    &:hover {
                        opacity: .7;
                    }
            
                    &.active {
                        font-weight: 1000;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }