.modal-wrapper {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);

    &.light {
        background-color: rgba(255, 255, 255, .3);

        .modal-container {
            background-color: #fff;
            backdrop-filter: none;
        }
    }

    .modal-container {
        margin: 0 8vw;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid hsla(30,0%,100%,.1);
        backdrop-filter: blur(20px);
    }

    &.show {
        display: flex;
        animation: showModal .2s;
    
        @keyframes showModal {
            from {
                opacity: 0;
            } to {
                opacity: 1;
            }
        }
    }
}