.profile-page-container {
    width: 100%;
    display: flex;

    .profile-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        margin: 0 14vw;
        margin-top: 80px;
        height: 550px;
        overflow-y: auto;

        .profile-card-body-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: fill-available;

            .profile-card-content-container {
                display: flex;
                width: fill-available;
                height: fill-available;
                padding-left: 30px;
                padding-top: 40px;
                padding-bottom: 40px;

                .profile-card-content-settings-container {
                    opacity: 0;
                    transition: .2s;
                    display: none;

                    &.show {
                        opacity: 1;
                        display: block;
                    }

                    .profile-card-content-settings-item-container {
                        margin-top: 15px;

                        .profile-card-content-settings-item-name {
                            font-size: 17px;
                            color: #fff;
                            font-family: 'SF-Bold';
                            margin: 0;
                            line-height: 28px;
                            transition: .2s;
                            padding-bottom: 10px;
                        }
                    }

                    .profile-card-content-settings-last-enter-container {
                        margin-top: 40px;

                        .profile-card-content-settings-last-enter-header {
                            font-size: 19px;
                            color: #fff;
                            font-family: 'SF-Bold';
                            margin: 0;
                            line-height: 28px;
                        }

                        .profile-card-content-settings-last-enter-time {
                            font-size: 16px;
                            color: #fff;
                            font-family: 'SF-Regular';
                            margin: 0;
                            margin-top: 30px;
                            line-height: 23px;
                        }

                        .profile-card-content-settings-last-enter-device {
                            font-size: 16px;
                            color: #fff;
                            font-family: 'SF-Regular';
                            margin: 0;
                            margin-top: 10px;
                            line-height: 23px;
                        }
                    }
                }

                .profile-card-content-info-container {
                    display: flex;
                    flex-direction: column;
                    opacity: 0;
                    transition: .2s;
                    display: none;

                    &.show {
                        opacity: 1;
                        display: block;
                    }

                    .profile-card-content-info-row-container {
                        margin-top: 12px;

                        .profile-card-content-info-row-name {
                            font-size: 18px;
                            color: #fff;
                            font-family: 'SF-Bold';
                            margin: 0;
                            line-height: 26px;
                            transition: .2s;
                        }

                        .profile-card-content-info-row-value {
                            font-size: 15px;
                            color: #fff;
                            font-family: 'SF-Regular';
                            margin: 0;
                            line-height: 24px;
                            transition: .2s;
                        }
                    }
                }
            }

            .profile-card-navigation-container {
                display: flex;
                flex-direction: column;
                border-right: 1px solid hsla(30,0%,100%,.1);
                height: 100%;
                justify-content: center;
                padding-right: 30px;
                margin-top: 40px;
                
                .profile-card-navigation-item-container {
                    margin: 20px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .profile-card-navigation-item-indicator {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        background-color: #5c03bc;
                        margin-right: 15px;
                        opacity: 0;
                        transition: .2s;

                        &.active {
                            opacity: 1;
                        }
                    }

                    .profile-card-navigation-item {
                        font-size: 19px;
                        color: #fff;
                        font-family: 'SF-Bold';
                        margin: 0;
                        line-height: 28px;
                        transition: .2s;

                        &:hover {
                            color: #5c03bc;
                        }

                        &.active {
                            color: #5c03bc;
                        }
                    }
                }
            }
        }
        
        .profile-card-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap-reverse;

            .profile-card-navigation-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
            }

            .profile-card-header {
                font-size: 21px;
                color: #fff;
                font-family: 'SF-Bold';
                margin: 0;
                line-height: 42px;
            }
        }
    }
}