.phone-menu-container {
    opacity: 0;
    display: none;
    flex-direction: column;
    backdrop-filter: blur(20px);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    &.light {
        .phone-menu-route {
            color: #2A2927;

            .phone-menu-platforms-container {
                .phone-menu-platform-item {
                    color: #2A2927;
                }
            }
        }
    }

    .phone-menu-close-icon {
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .phone-menu-route {
        font-family: 'SF-Bold';
        margin-left: 2vw;
        width: fit-content;
        text-decoration: none;
        color: #fff;
        margin-top: 40px;
        transition: .3s;
        font-size: 38px;

        &:hover {
            color: #6326a3;
        }

        .phone-menu-platforms-container {
            display: flex;
            flex-direction: column;
            margin-left: 30px;
            opacity: 0;
            height: 0;
            visibility: hidden;
            transition: .2s ease-out;

            .phone-menu-platform-item {
                color: #fff;
                text-decoration: none;
                display: flex;
                align-items: center;
                font-size: 25px;
                margin-top: 15px;

                &:hover {
                    opacity: .8;
                }
            }

            &.show {
                opacity: 1;
                visibility: visible;
                height: 300px;
            }
        }
    }

    &.show {
        opacity: 1;
        display: flex;
        animation: showPhoneMenu .3s;

        @keyframes showPhoneMenu {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        .phone-menu-route {
            animation: showPhoneMenuRoutes .4s;

            @keyframes showPhoneMenuRoutes {
                from {
                    transform: translateX(-300px);
                }

                to {
                    transform: translateX(0px);
                }
            }
        }
    }
}