.support-modal-container {
    .support-modal-actions-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .support-modal-header {
        font-size: 24px;
        color: #2A2927;
        font-family: 'SF-Bold';
        margin: 0;
    }

    .support-modal-subheader {
        margin: 0;
        font-size: 18px;
        margin-top: 20px;
        color: #2A2927;
        font-family: 'SF-Regular';
    }
}

.support-block-container {
    margin: 60px 14vw;
    padding: 80px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    
    @media screen and (max-width: 860px) {
        padding: 30px;
    }

    .support-block-input-row {
        margin-top: 25px;
    }

    .support-block-button-container {
        display: flex;
        margin-top: 30px;
        flex-direction: row;
        justify-content: flex-end;
    }

    .support-block-header {
        font-size: 36px;
        color: #2A2927;
        font-family: 'SF-Bold';
        margin: 0;
    }

    .support-block-subheader {
        font-size: 18px;
        color: #828282;
        font-family: 'SF-Regular';
        padding-top: 20px;
        margin: 0;
        padding-bottom: 40px;
    }
}