.base-button {
    border: none;
    outline: none;
    color: #fff;
    background-color: #6326a3;
    border-radius: 15px;
    padding: 17px 26px;
    font-family: 'SF-Regular';
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    transition: background-color .4s;

    &:hover {
        background-color: #5c03bc;
    }
}