.main-page-wrapper {
    position: relative;
}

.main-page-container {
    z-index: 2;
    position: relative;

    .main-page-vpn-work-illustration-container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;

        .main-page-vpn-work-illutration-content-container {
            border-radius: 30px;
            margin-top: 40px;

            .second {
                transition: .2s;
            }

            .first {
                transition: .2s;
            }

            &.second {
                .second {
                    display: block;
                    animation: .2s showIllustration;
                }

                .first {
                    display: none;
                }
            }

            &.first {
                .second {
                    display: none;
                }

                .first {
                    display: block;
                    animation: .2s showIllustration;
                }
            }

            @keyframes showIllustration {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            &.show {
                opacity: 1;
                animation: .3s showVpnWorkIllustration;

                @keyframes showVpnWorkIllustration {
                    from {
                        opacity: 0;
                        transform: translateY(30px);
                    }

                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }


        }

        .main-page-vpn-work-illutration-controls-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            opacity: 0;

            &.show {
                opacity: 1;
                animation: .3s showVpnWorkIllustration;

                @keyframes showVpnWorkControls {
                    from {
                        opacity: 0;
                        transform: translateY(-30px);
                    }

                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            .main-page-vpn-work-illustration-controls-item-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 40px;
                transition: .2s;

                .main-page-vpn-work-illustration-controls-span {
                    width: 0px;
                    height: 0px;
                    background-color: #38dfe2;
                    border-radius: 50%;
                    transition: .3s;
                    margin-right: 10px;
                }

                &:hover {
                    opacity: .8;
                }

                &.primary {
                    .main-page-vpn-work-illustration-controls-span {
                        width: 5px;
                        height: 5px;
                    }

                    .main-page-vpn-work-illustration-controls-text {
                        color: #38dfe2;
                    }
                }

                .main-page-vpn-work-illustration-controls-text {
                    text-align: center;
                    font-size: 16px;
                    margin: 0;
                    color: #d9d9d9;
                    font-family: 'SF-Bold';
                    transition: .3s;
                    cursor: pointer;
                }
            }
        }
    }

    .main-page-learn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px;
        margin: 200px 14vw;

        @media screen and (max-width: 860px) {
            padding: 30px;
        }

        .main-page-learn-top-header {
            text-align: center;
            font-size: 12px;
            color: #fff;
            font-family: 'SF-Bold';
            margin: 0;
        }

        .main-page-learn-header {
            text-align: center;
            font-size: 44px;
            color: #fff;
            margin: 0;
            margin-top: 30px;
            font-family: 'SF-Bold';
        }

        .main-page-learn-button-container {
            margin-top: 20px;
        }

        .main-page-learn-desc {
            text-align: center;
            font-size: 18px;
            color: #828282;
            font-family: 'SF-Regular';
            margin-top: 25px;
            width: 60%;

            @media screen and (max-width: 1000px) {
                width: 70%;
            }

            @media screen and (max-width: 860px) {
                width: 80%;
            }

            @media screen and (max-width: 750px) {
                width: 90%;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }

    .main-page-tarifs-container {
        margin-top: 200px;
        transition: .1s;

        .main-page-tarifs-header-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            transition: .1s;

            .main-page-tarifs-header-symbol {
                font-size: 150px;
                transition: .1s;
                line-height: 80px;
                color: #5c03bc;
                font-family: 'SF-Bold';
                margin: 0 30px;

                @media screen and (max-width: 1300px) {
                    font-size: 120px;
                    line-height: 60px;
                    margin: 0 26px;
                }

                @media screen and (max-width: 1000px) {
                    font-size: 78px;
                    transform: none !important;
                    line-height: 50px;
                    margin: 0 22px;
                }

                @media screen and (max-width: 890px) {
                    font-size: 55px;
                    line-height: 38px;
                    margin: 0 18px;
                }

                @media screen and (max-width: 500px) {
                    font-size: 32px;
                    line-height: 13px;
                    margin: 0 14px;
                }
            }
        }

        .main-page-tarifs-list-container {
            backdrop-filter: blur(20px);
            display: flex;
            flex-direction: column;
            word-break: break-word;
            padding: 0 10vw;
            padding-bottom: 60px;
            align-items: center;
            position: relative;

            .main-page-tarifs-accent-bg {
                z-index: -2;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fff;
                opacity: .8;
                backdrop-filter: blur(20px);
                z-index: -1;
            }


            .main-page-tarifs-list-header {
                font-size: 72px;
                margin-top: 50px;
                line-height: 95px;
                transition: .1s;
                font-family: 'SF-Bold';
                color: #2A2927;

                .primary {
                    color: #5c03bc;
                }

                width: 90%;

                @media screen and (max-width: 1000px) {
                    width: 95%;
                }

                @media screen and (max-width: 860px) {
                    width: 95%;
                    font-size: 60px;
                    line-height: 75px;
                }

                @media screen and (max-width: 500px) {
                    width: 100%;
                    font-size: 45px;
                    line-height: 55px;
                }
            }

            .main-page-tarifs-list-inner-container {
                display: grid;
                grid-auto-columns: 1fr;
                grid-column-gap: 0px;
                grid-template-columns: 1.2fr 1fr;
                grid-row-gap: 50px;
                grid-template-rows: auto auto;

                @media screen and (max-width: 640px) {
                    display: flex;
                    flex-direction: column;
                }

                .main-page-tarifs-list-inner-desc {
                    grid-column-end: 3;
                    grid-column-start: 2;
                    grid-row-start: 1;
                    grid-row-end: 2;
                    font-size: 16px;
                    line-height: 24px;
                    color: #696969;
                    font-family: 'SF-Regular';
                    margin-top: 25px;
                }

                .main-page-tarifs-inner-block {
                    max-width: 300px;
                    opacity: 0;

                    &.show {
                        opacity: 1;
                        animation: .3s showInnerBlock;

                        @keyframes showInnerBlock {
                            from {
                                opacity: 0;
                                transform: translateY(60px);
                            }

                            to {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }


                    .main-page-tarifs-inner-header {
                        font-size: 46px;
                        line-height: 45px;
                        font-family: 'SF-Bold';
                        color: #5c03bc;
                    }

                    .main-page-tarifs-inner-desc {
                        font-size: 19px;
                        line-height: 28px;
                        color: #696969;
                        font-family: 'SF-Regular';
                        margin-top: 25px;
                    }

                    &.first {
                        justify-self: start;
                        align-self: start;
                        grid-row-start: 2;
                        grid-row-end: 3;
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }

                    &.second {
                        justify-self: start;
                        align-self: end;
                        grid-row-start: 2;
                        grid-row-end: 3;
                        grid-column-start: 2;
                        grid-column-end: 3;
                        margin-top: 30px;
                    }

                    &.third {
                        justify-self: end;
                        grid-row-start: 3;
                        grid-row-end: 4;
                        grid-column-start: 1;
                        grid-column-end: 2;
                    }

                    &.four {
                        justify-self: end;
                        grid-row-start: 3;
                        grid-row-end: 4;
                        grid-column-start: 2;
                        grid-column-end: 3;
                        margin-top: 30px;
                    }
                }

                .main-page-tarifs-list-left-container {
                    flex: 1;
                    display: flex;
                    min-width: 260px;
                }

                width: 90%;

                @media screen and (max-width: 1000px) {
                    width: 85%;
                }

                @media screen and (max-width: 860px) {
                    width: 95%;
                }

                @media screen and (max-width: 750px) {
                    width: 95%;
                }

                @media screen and (max-width: 500px) {
                    width: 100%;
                }

                .main-page-tarifs-list-right-container {
                    flex: 1;
                    display: flex;
                    min-width: 260px;
                    flex-direction: column;

                    .main-page-tarifs-list-button-container {
                        margin-top: 20px;
                    }

                    .main-page-tarifs-list-right-content {
                        font-size: 18px;
                        color: #828282;
                        font-family: 'SF-Regular';
                        margin-top: 25px;
                    }
                }
            }

            .main-page-tarif-container {
                word-break: break-word;
                width: 290px;
                margin-top: 30px;
                border: 1px solid rgba(72, 72, 72, 0.1);
                border-radius: 30px;
                padding: 40px;

                .main-page-tarif-name {
                    color: #fff;
                    font-size: 36px;
                    font-family: 'SF-Bold';
                    margin: 0;
                    padding-bottom: 30px;
                }

                .main-page-tarif-desc {
                    border-top: 1px solid rgba(72, 72, 72, 0.1);
                    font-size: 17px;
                    color: #828282;
                    font-family: 'SF-Regular';
                    padding-top: 20px;
                    margin: 0;
                    padding-bottom: 30px;
                }
            }
        }
    }

    .main-page-faq-container {
        padding: 80px;
        border-radius: 30px;
        border: 1px solid hsla(30, 0%, 100%, .1);
        margin-top: 90px;
        backdrop-filter: blur(20px);
        opacity: 0;

        @media screen and (max-width: 860px) {
            padding: 30px;
        }

        @media screen and (max-width: 600px) {
            padding: 20px;
            margin: 0 4vw;
        }

        &.show {
            opacity: 1;
            animation: .3s showFaqQuestioBlock;

            @keyframes showFaqQuestioBlock {
                from {
                    opacity: 0;
                    transform: translateY(60px);
                }

                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .main-page-faq-header {
            font-size: 36px;
            color: #fff;
            font-family: 'SF-Bold';
            margin: 0;
            line-height: 52px;

            .primary {
                color: #5c03bc;
            }
        }

        .main-page-faq-question-row {
            display: flex;
            flex-direction: row;
            align-items: start;
            margin-top: 25px;
            flex: 1;
            gap: 40px;
            flex-wrap: wrap;

            .main-page-faq-question-container {
                display: flex;
                flex-direction: column;
                min-width: 240px;
                flex: 1;
                opacity: 0;

                &.show {
                    opacity: 1;
                    animation: .3s showFaqQuestion;

                    @keyframes showFaqQuestion {
                        from {
                            opacity: 0;
                            transform: translateY(20px);
                        }

                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }

                .main-page-faq-question-header {
                    font-size: 19px;
                    line-height: 27px;
                    color: #fff;
                    font-family: 'SF-Bold';
                    margin: 0;
                    margin-top: 30px;
                    border-top: 1px solid hsla(30, 0%, 100%, .1);
                    padding-top: 20px;
                }

                .main-page-faq-question-desc {
                    margin-top: 30px;
                    word-wrap: break-word;
                    font-size: 19px;
                    line-height: 28px;
                    color: #d9d9d9;
                    font-family: 'SF-Regular';
                }
            }
        }
    }

    .main-page-second-container {
        // margin-top: 400px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        opacity: 0;

        &.show {
            animation: showSecondContainer .6s;
            opacity: 1;

            @keyframes showSecondContainer {
                from {
                    opacity: 0;
                    transform: translateY(130px);
                }

                to {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }


        .main-page-second-left-container {
            display: flex;
            flex: 1 1;
            margin-top: 60px;
            flex-direction: column;

            .main-page-second-left-top-text {
                font-family: 'SF-Bold';
                font-size: 13px;
                margin: 0;
                color: #fff;

                .primary {
                    color: #5c03bc;
                }

                @media screen and (max-width: 1130px) {
                    transform: none !important;
                }
            }

            .main-page-second-left-text {
                font-family: 'SF-Bold';
                font-size: 62px;
                line-height: 101px;
                margin: 0;
                margin-top: 20px;
                color: #fff;
                margin-right: 20px;

                .primary {
                    color: #5c03bc;
                }

                @media screen and (max-width: 1130px) {
                    transform: none !important;
                    font-size: 32px;
                    line-height: 63px;
                }
            }
        }

        .main-page-second-right-container {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            word-break: break-word;
            min-width: 290px;

            @media screen and (max-width: 600px) {
                flex-direction: row;
                min-width: 100%;
                overflow-x: auto;
                margin-bottom: 60px;

                &::-webkit-scrollbar {
                    width: 2px;
                    height: 5px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: #555;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }

            .main-page-second-block-container {
                margin-top: 50px;
                word-wrap: break-word;
                min-width: 290px;
                opacity: 0;

                @media screen and (max-width: 600px) {
                    min-width: 100%;
                    max-width: 100%;
                }

                &.show {
                    opacity: 1;
                    animation: .3s ease-in showSecondBlock;

                    @keyframes showSecondBlock {
                        from {
                            opacity: 0;
                            transform: translateX(100px);
                        }

                        to {
                            opacity: 1;
                            transform: translateX(0px);
                        }
                    }
                }

                .main-page-second-block-header {
                    font-family: 'SF-Bold';
                    font-size: 22px;
                    margin: 0;
                    color: #fff;
                }

                .main-page-second-block-desc {
                    margin-top: 30px;
                    word-wrap: break-word;
                    font-size: 19px;
                    line-height: 28px;
                    color: #b2b2b2;
                    font-family: 'SF-Regular';

                    @media screen and (max-width: 600px) {
                        font-size: 17px;
                    }
                }
            }
        }
    }

    .main-page-hero-container {
        z-index: 2;
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .main-page-hero-subtext {
            font-size: 30px;
            color: #fff;
            margin: 0;
            font-family: 'SF-Regular';
            z-index: 2;
            margin-top: 40px;
            line-height: 40px;

            animation: showHeroText .6s;

            @media screen and (max-width: 580px) {
                font-size: 20px;
                width: 100%;
            }
        }

        .main-page-hero-text {
            font-size: 120px;
            color: #fff;
            margin: 0;
            line-height: 85px;
            font-family: 'SF-Bold';
            z-index: 2;

            .secondary {
                font-family: 'SF-Regular', sans-serif;
                font-size: 42px;
            }

            @media screen and (max-width: 1150px) {
                font-size: 92px;

                .secondary {
                    font-size: 42px;
                }
            }

            @media screen and (max-width: 856px) {
                font-size: 66px;

                .secondary {
                    font-size: 32px;
                }
            }

            @media screen and (max-width: 580px) {
                font-size: 38px;

                .secondary {
                    font-size: 20px;
                }

                width: 100%;
            }

            animation: showHeroText .6s;

            @keyframes showHeroText {
                from {
                    opacity: 0;
                    transform: translateX(-130px);
                }

                to {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
        }
    }
}

.main-page-descriptions-container {
    position: relative;
    margin-top: 60px;
    flex-direction: column;

    .main-page-descriptions-header {
        font-size: 72px;
        line-height: 95px;
        transition: .1s;
        font-family: 'SF-Bold';
        color: #2A2927;
        width: 90%;
        padding-top: 60px;

        @media screen and (max-width: 1000px) {
            width: 95%;
        }

        @media screen and (max-width: 860px) {
            width: 95%;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
        }

        .primary {
            color: #5c03bc;
        }
    }

    .main-page-descriptions-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .8;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        z-index: -1;
    }

    .main-page-descriptions-content-wrapper {
        display: flex;
        align-items: center;

        .main-page-descriptions-content-container {
            flex: 1;
            padding-left: 14vw;
            margin-top: 60px;
            margin-bottom: 60px;

            // @media screen and (max-width: 1150px) {
            //     padding: 0 14vw;
            // }

            .main-page-description-content-item {
                .main-page-description-content-header {
                    font-size: 46px;
                    font-family: 'SF-Bold';
                    color: #5c03bc;
                }

                .main-page-description-content {
                    font-size: 19px;
                    line-height: 28px;
                    color: #696969;
                    font-family: 'SF-Regular';
                    margin-top: 25px;
                }
            }
        }

        .main-page-descriptions-model-container {
            flex: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1150px) {
                display: none;
            }
        }
    }
}

.main-page-vpn-work-switch-container {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: fit-content;
    border-radius: 50px;
    backdrop-filter: blur(20px);
    margin-bottom: 50px;

    .main-page-vpn-work-switch-background {
        z-index: -1;
        height: 100%;
        background-color: #fff;
        position: absolute;
        width: 50%;
        border-radius: 50px;
        backdrop-filter: blur(20px);
        transition: .2s ease-in-out;

        @media screen and (max-width: 600px) {
            width: 100%;
            height: 50%;

            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 50px;
            border-top-left-radius: 50px;
        }

        &.switched {
            margin-left: 50%;

            @media screen and (max-width: 600px) {
                margin-left: 0;
                top: 50%;
                border-bottom-right-radius: 50px;
                border-bottom-left-radius: 50px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .main-page-vpn-work-swtich-controls-container {
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }

        .main-page-vpn-work-switch-control {
            margin: 0;
            padding: 20px 40px;
            text-align: center;
            font-size: 16px;
            color: #d9d9d9;
            font-family: 'SF-Bold';
            transition: .2s ease-in-out;
            cursor: pointer;

            &.switched {
                color: #2A2927;
            }
        }
    }
}

.main-page-vpn-work-switch-wrapper {
    justify-content: center;
    display: flex;
    width: 100%;
}