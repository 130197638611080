body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    position: relative;
    overflow-x: hidden;
    height: 100%;
}

.content-container {
    background-color: #15111b;
    height: 100%;
    display: flex;
    position: relative;
    
    &.light {
        background-color: #fff;    
    }
}

#root {
    position: relative;
    height: 100%;
}

.content-margin-wrapper {
    margin: 0 14vw;

    @media screen and (max-width: 1024px) {
        margin: 0 10vw;
    }

    @media screen and (max-width: 800px) {
        margin: 0 7vw;
    }

    @media screen and (max-width: 500px) {
        margin: 0 4vw;
    }

    @media screen and (max-width: 400px) {
        margin: 0 3vw;
    }
}

html {
    position: relative;
    height: 100%;
}

::-webkit-scrollbar {
    width: 6px;
  }

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #5c03bc;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}