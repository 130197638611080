.subscription-page-container {
    width: 100%;
    margin: 0 auto;
    z-index: 2;

    .subscription-page-content-platforms {
        text-align: center;
        margin-top: 60px;
        color: #fff;
        font-size: 27px;
        font-family: 'SF-Regular';
    }

    .subscription-page-content-header {
        position: relative;
        font-size: 101px;
        font-family: 'SF-Bold';
        margin-top: 60px;
        text-transform: uppercase;
        margin-left: -0.65em;
        margin-bottom: 120px;
        color: #fff;
        padding: 0 14vw;

        &.platforms {
            font-size: 70px;
        }

        @media screen and (max-width: 1300px) {
            margin-left: 0;
        }

        @media screen and (max-width: 680px) {
            font-size: 78px;
        }

        @media screen and (max-width: 580px) {
            font-size: 58px;
        }

        @media screen and (max-width: 380px) {
            font-size: 38px;
        }
    }

    .subscription-prices-container {
        margin-top: 100px;

        .subscription-prices-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 20px;

            .subscription-price-item {
                padding: 40px;
                border-radius: 30px;
                flex: 1;
                border: 1px solid rgba(255, 255, 255, 0.1);
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);

                .subscription-price-discount-items {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    padding-bottom: 30px;
                }

                .subscription-price-discount-container {
                    padding: 10px 20px;
                    border-radius: 12px;
                    background-color: #5c03bc;
                    width: fit-content;

                    .subscription-price-discount {
                        text-align: center;
                        color: #fff;
                        font-size: 15px;
                        margin: 0;
                        font-family: 'SF-Bold', sans-serif;
                    }

                    &.hidden {
                        padding: 8px 18px;
                        opacity: .6;

                        .subscription-price-discount {
                            font-size: 13px;
                        }
                    }

                    &.promo {
                        left: 30px;
                    }
                }

                .subscription-price {
                    font-family: 'SF-Bold';
                    font-size: 22px;
                    margin: 0;
                    margin-bottom: 30px;
                    color: #fff;
                    margin-top: 20px;
                    margin-bottom: 30px;
                }

                .subscription-price-duration {
                    font-family: 'SF-Bold';
                    font-size: 26px;
                    margin: 0;
                    color: #5c03bc;
                    position: relative;

                    &.hidden {
                        opacity: .6;
                        font-size: 18px;
                        text-decoration: line-through;
                    }

                    &.promo {
                        font-size: 28px;
                    }

                    // &::after {
                    //     width: 50%;
                    //     height: 3px;
                    //     left: 0;
                    //     top: 0;
                    //     bottom: 0;
                    //     background-color: red;
                    //     display: inline-block;
                    //     position: absolute;
                    //     content: '';
                    //     transform: rotateZ(30deg);
                    // }
                }
            }
        }
    }

    .subscription-tarifs-list-container {
        backdrop-filter: blur(20px);
        display: flex;
        flex-direction: column;
        word-break: break-word;
        padding-bottom: 60px;
        margin-top: 60px;
        position: relative;

        .subscription-tarifs-accent-bg {
            z-index: -2;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: .8;
            backdrop-filter: blur(20px);
            z-index: -1;
        }


        .subscription-tarifs-list-header {
            font-size: 72px;
            margin-top: 50px;
            line-height: 95px;
            transition: .1s;
            font-family: 'SF-Bold';
            color: #2A2927;

            .primary {
                color: #5c03bc;
            }

            width: 90%;

            @media screen and (max-width: 1000px) {
                width: 95%;
            }

            @media screen and (max-width: 860px) {
                width: 95%;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }

        .subscription-tarifs-list-inner-container {
            display: grid;
            grid-auto-columns: 1fr;
            grid-column-gap: 0px;
            grid-template-columns: 1.2fr 1fr;
            grid-row-gap: 50px;
            grid-template-rows: auto auto;

            @media screen and (max-width: 640px) {
                display: flex;
                flex-direction: column;
            }

            .subscription-tarifs-list-inner-desc {
                grid-column-end: 3;
                grid-column-start: 2;
                grid-row-start: 1;
                grid-row-end: 2;
                font-size: 16px;
                line-height: 24px;
                color: #696969;
                font-family: 'SF-Regular';
                margin-top: 25px;
            }

            .subscription-tarifs-inner-block {
                max-width: 300px;

                &.show {
                    opacity: 1;
                    animation: .3s showInnerBlock;

                    @keyframes showInnerBlock {
                        from {
                            opacity: 0;
                            transform: translateY(60px);
                        }

                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                }


                .subscription-tarifs-inner-header {
                    font-size: 46px;
                    font-family: 'SF-Bold';
                    color: #5c03bc;
                    word-break: normal;
                }

                .subscription-tarifs-inner-desc {
                    font-size: 19px;
                    line-height: 28px;
                    color: #696969;
                    font-family: 'SF-Regular';
                    margin-top: 25px;
                }

                &.first {
                    justify-self: start;
                    align-self: start;
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 1;
                    grid-column-end: 2;
                }

                &.second {
                    justify-self: start;
                    align-self: end;
                    grid-row-start: 2;
                    grid-row-end: 3;
                    grid-column-start: 2;
                    grid-column-end: 3;
                    margin-top: 30px;
                }

                &.third {
                    justify-self: end;
                    grid-row-start: 3;
                    grid-row-end: 4;
                    grid-column-start: 1;
                    grid-column-end: 2;
                }

                &.four {
                    justify-self: end;
                    grid-row-start: 3;
                    grid-row-end: 4;
                    grid-column-start: 2;
                    grid-column-end: 3;
                    margin-top: 30px;
                }
            }

            .subscription-tarifs-list-left-container {
                flex: 1;
                display: flex;
                min-width: 260px;
            }

            width: 90%;

            @media screen and (max-width: 1000px) {
                width: 85%;
            }

            @media screen and (max-width: 860px) {
                width: 95%;
            }

            @media screen and (max-width: 750px) {
                width: 95%;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
            }

            .subscription-tarifs-list-right-container {
                flex: 1;
                display: flex;
                min-width: 260px;
                flex-direction: column;

                .subscription-tarifs-list-button-container {
                    margin-top: 20px;
                }

                .subscription-tarifs-list-right-content {
                    font-size: 18px;
                    color: #828282;
                    font-family: 'SF-Regular';
                    margin-top: 25px;
                }
            }
        }

        .subscription-tarif-container {
            word-break: break-word;
            width: 290px;
            margin-top: 30px;
            border: 1px solid rgba(72, 72, 72, 0.1);
            border-radius: 30px;
            padding: 40px;

            .subscription-tarif-name {
                color: #fff;
                font-size: 36px;
                font-family: 'SF-Bold';
                margin: 0;
                padding-bottom: 30px;
            }

            .subscription-tarif-desc {
                border-top: 1px solid rgba(72, 72, 72, 0.1);
                font-size: 17px;
                color: #828282;
                font-family: 'SF-Regular';
                padding-top: 20px;
                margin: 0;
                padding-bottom: 30px;
            }
        }
    }

    .subscription-info-list {
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        justify-content: space-around;
        gap: 20px;
        flex-wrap: wrap;

        @media screen and (max-width: 1000px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }

        @media screen and (max-width: 550px) {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 1fr;
        }

        .subscription-info-item {
            flex: 1;
            
            .subscription-info-header {
                font-size: 18px;
                font-family: 'SF-Bold';
                color: #fff;
                word-break: normal;
            }

            .subscription-info-description {
                font-size: 18px;
                font-family: 'SF-Regular';
                color: #828282;
                word-break: normal;
            }
        }
    }


    .subscription-platforms-container {
        margin-top: 70px;

        .subscription-platforms-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-around;
            gap: 30px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 40px;
            border-radius: 30px;

            @media screen and (max-width: 900px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
            }

            @media screen and (max-width: 450px) {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
            }

            .subscription-platform-item {
                display: flex;
                flex-direction: column;
                transition: .2s ease-in;
                cursor: pointer;
                justify-content: center;


                &:hover {
                    opacity: .7;
                }

                .subscription-platform-icon {
                    margin: 0 auto;
                }

                .subscription-platform-name {
                    color: #fff;
                    margin-top: 10px;
                    text-align: center;
                    font-family: 'SF-Bold';
                    font-size: 16px;
                    color: #fff;
                    margin-bottom: 0;
                }
            }
        }


    }

    .subscription-descriptions-container {
        position: relative;
        margin-top: 60px;
        flex-direction: column;
        z-index: 1;
        
        .subscription-descriptions-header {
            font-size: 72px;
            line-height: 95px;
            transition: .1s;
            font-family: 'SF-Bold';
            color: #2A2927;
            width: 90%;
            padding-top: 60px;
            padding-left: 14vw;

            @media screen and (max-width: 1150px) {
                padding-left: 10px;
            }

            @media screen and (max-width: 1000px) {
                width: 95%;
            }

            @media screen and (max-width: 860px) {
                width: 95%;
                font-size: 60px;
            }

            @media screen and (max-width: 500px) {
                width: 100%;
                line-height: 58px;
                font-size: 40px;
            }

            .primary {
                color: #5c03bc;
            }
        }

        .subscription-descriptions-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: .8;
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            z-index: -1;
        }

        .subscription-descriptions-content-wrapper {
            display: flex;
            align-items: center;

            @media screen and (max-width: 1150px) {
                flex-direction: column;
            }

            .subscription-descriptions-content-container {
                flex: 1;
                padding-left: 14vw;
                margin-top: 30px;
                margin-bottom: 60px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media screen and (max-width: 1150px) {
                    padding: 10px;
                }

                .subscription-description-content-item {
                    .subscription-description-content-header {
                        font-size: 32px;
                        font-family: 'SF-Bold';
                        color: #2A2927;
                        margin-bottom: 0;
                    }

                    .subscription-description-content {
                        font-size: 19px;
                        line-height: 28px;
                        color: #696969;
                        font-family: 'SF-Regular';
                        margin-top: 5px;
                    }
                }
            }

            .subscription-descriptions-model-container {
                flex: 1.5;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}