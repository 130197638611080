.not-found-block-container {
    margin: 60px 14vw;
    padding: 80px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    height: fit-content;
    
    @media screen and (max-width: 860px) {
        padding: 30px;
    }

    .not-found-header {
        font-size: 42px;
        color: #2A2927;
        font-family: 'SF-Bold';
        margin: 0;
    }

    .not-found-subheader {
        font-size: 18px;
        color: #828282;
        font-family: 'SF-Regular';
        margin: 0;
        margin-top: 30px;
    }

    .not-found-action-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 40px;
    }
}