.input-container {
    width: 100%;
    
    &.light {
        .input-label {
            color: #2A2927;
        }

        .input {
            color: #2A2927;
            border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        }
    }

    .input {
        color: #b2b2b2;
        background-color: transparent;
        width: 100%;
        margin-top: 6px;
        border: 0;
        padding-bottom: 3px;
        outline: 0;
        font-family: 'SF-Regular';
        font-size: 14px;
        border-bottom: 2px solid hsla(30,0%,100%,.1);
        transition: .2s;

        &.errored {
            border-bottom: 2px solid #b43d3d;
        }
    }

    .input-error {
        color: #b43d3d;
        font-family: 'SF-Regular';
        font-size: 13px;
        margin: 0;
        margin-top: 3px;
        transition: .2s;
    }

    .input-label {
        font-size: 15px;
        color: #fff;
        margin: 0;
        font-family: 'SF-Bold';
    }
}