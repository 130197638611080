.download-page-content {
    padding-top: 300px;
    padding-bottom: 300px;
    display: flex;
    width: 100%;
    align-items: center;

    .download-page-left {
        flex: 1;
        z-index: 2;

        .download-page-header {
            font-size: 70px;
            width: 60%;
            color: #fff;
            margin: 0;
            font-family: 'SF-Bold';
            z-index: 2;

            @media screen and (max-width: 1200px) {
                font-size: 60px;
            }

            @media screen and (max-width: 1000px) {
                font-size: 50px;
            }

            @media screen and (max-width: 900px) {
                font-size: 45px;
            }

            @media screen and (max-width: 800px) {
                font-size: 43px;
            }

            @media screen and (max-width: 700px) {
                font-size: 43px;
                width: 100%;
            }

            @media screen and (max-width: 600px) {
                font-size: 43px;
            }

            .primary {
                color: #5c03bc;
            }
        }

        .download-page-subheader {
            font-size: 50px;
            color: #fff;
            margin: 0;
            font-family: 'SF-Regular';
            z-index: 2;

            @media screen and (max-width: 1200px) {
                font-size: 45px;
            }

            @media screen and (max-width: 1000px) {
                font-size: 40px;
            }

            @media screen and (max-width: 900px) {
                font-size: 35px;
            }

            @media screen and (max-width: 800px) {
                font-size: 30px;
            }

            @media screen and (max-width: 700px) {
                font-size: 25px;
            }

            @media screen and (max-width: 600px) {
                font-size: 24px;
            }
        }

        .download-page-desc {
            font-size: 20px;
            color: #fff;
            margin: 0;
            font-family: 'SF-Regular';
            z-index: 2;

            @media screen and (max-width: 800px) {
                font-size: 19px;
            }

            @media screen and (max-width: 700px) {
                font-size: 17px;
            }

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }

        .download-page-actions-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            margin-top: 40px;

            @media screen and (max-width: 1000px) {
                flex-direction: column;
                align-items: baseline;
            }

            .download-page-action-button-container {
                padding: 21px 30px;
                border-radius: 12px;
                border: 1px solid rgba(224, 224, 224, 0.289);
                cursor: pointer;
                transition: .2s;

                &:hover {
                    opacity: .8;
                }

                .download-page-actions-button {
                    margin: 0;
                    text-align: center;
                    font-size: 16px;
                    color: #d9d9d9;
                    display: flex;
                    align-items: ce;
                    font-family: 'SF-Bold';
                }
            }
        }
    }

    .download-page-right {
        position: absolute;
        z-index: 1;
    
        .download-page-right-image {
            width: 100%;
            margin-left: 180px;

            @media screen and (max-width: 1400px) {
                width: 100%;
                margin-left: 130px;
            }

            @media screen and (max-width: 1200px) {
                margin-left: 40px;
                width: 110%;
            }

            @media screen and (max-width: 900px) {
                margin-left: 0px;
                width: 120%;
            }

            @media screen and (max-width: 800px) {
                margin-left: -50px;
                width: 130%;
            }

            @media screen and (max-width: 700px) {
                display: none;
                width: 140%;
                margin-left: -120px;
                height: 120%;
            }
        }
 
   }
}