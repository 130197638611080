.confidential-container {
    margin: 60px 14vw;
    padding: 80px;
    
    @media screen and (max-width: 860px) {
        padding: 30px;
    }

    .confidential-content {
        font-family: 'SF-Regular';
        font-size: 17px;
        overflow-wrap: anywhere;
        color: #828282;
    }
}