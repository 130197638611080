.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #15111b;
    border-bottom: 1px solid hsla(30,0%,100%,.1);
    z-index: 4;

    &.light {
        background-color: #fff;

        .header-block {
            .header-right-container {
                .header-right-route-list {
                    .header-right-route {
                        color: #2A2927;

                        &.platforms {
                            position: relative;
                        }
    
                        .header-right-platforms-container {
                            background-color: #fff;
                            border: 1px solid rgba(0, 0, 0, 0.06);
    
                            .header-right-platofrms-list-container {
                            
                                .header-right-platofrm-item {
                                    color: #2A2927;
        
                                    &:hover {
                                        color: #2A2927;
                                        opacity: .8;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        border: 1px solid rgba(0, 0, 0, 0.06);
    }

    .header-block {
        padding: 30px 14vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        z-index: 4;

        .header-left-container {
            display: flex;
            flex: 1 1;
        }

        .header-right-container {
            display: flex;
            flex: 1 1;
            flex-wrap: nowrap;
            justify-content: flex-end;

            .header-right-menu {
                display: none;

                @media screen and (max-width: 1100px) {
                    display: block;
                }
            }

            .header-right-route-list {
                overflow-wrap: unset;
                word-break: unset;
                display: flex;
                white-space: nowrap;
                align-items: center;
                gap: 30px;
                @media screen and (max-width: 1100px) {
                    display: none;
                }

                .header-right-route {
                    font-family: 'SF-Bold';
                    text-decoration: none;
                    color: #fff;
                    transition: .3s;

                    &.platforms {
                        position: relative;
                    }

                    .header-right-platforms-container {
                        margin: 0;
                        position: absolute;
                        border: 1px solid hsla(30,0%,100%,.1);
                        border-radius: 12px;
                        margin-top: 10px;
                        padding: 20px;
                        background-color: #15111b;
                        width: 400px;
                        opacity: 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        column-gap: 20px;
                        visibility: hidden;
                        transition: .2s ease-in;

                        &.show {
                            opacity: 1;
                            visibility: visible;
                        }

                        .header-right-platofrms-list-container {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                        
                            .header-right-platofrm-item {
                                color: #fff;
                                font-family: 'SF-Bold';
                                margin-bottom: 0;
                                text-decoration: none;
                                display: flex;
                                align-items: center;
    
                                &:hover {
                                    color: #fff;
                                    opacity: .8;
                                }
                            }
                        }
                    }

                    &:hover {
                        color: #6326a3;
                    }
                }
            }
        }
    }
}

.header-silly-container {
    background-color: #5C03BC;
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 4;
    justify-content: center;
    padding: 0 20px;

    .header-silly-text {
        margin: 0;
        color: #fff;
        font-family: 'SF-Bold';
        font-size: 17px;
        line-height: 21px;
        text-align: center;

        .primary {
            color: #73FF5C;
        }

        .link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}